var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-tip"},[_vm._v("满足以下条件时进入当前分支")]),_c('el-form',{attrs:{"model":_vm.nodeProps}},_vm._l((_vm.nodeProps.groups),function(group,groupIndex){return _c('div',{key:groupIndex,staticClass:"groups-item"},[(groupIndex !== 0)?_c('div',{staticStyle:{"padding":"15px 0","color":"#646a73"}},[_vm._v(" 或满足 ")]):_vm._e(),_c('div',{staticClass:"groups-main"},[_c('div',{staticClass:"groups-title"},[_c('span',[_vm._v("条件组"+_vm._s(groupIndex + 1))]),_c('i',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.deleteGroup(groupIndex)}}})]),_vm._l((group.conditions),function(condition,conditionIndex){return _c('div',{key:`${groupIndex}-${conditionIndex}`,staticClass:"options-item"},[_c('div',{staticClass:"options-title"},[_c('span',[_vm._v(_vm._s(conditionIndex === 0 ? '当' : '且'))]),_c('i',{staticClass:"el-icon-delete",attrs:{"color":"#9ca2a9"},on:{"click":function($event){return _vm.deleteOption(groupIndex, conditionIndex)}}})]),_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'groups.' + groupIndex + '.conditions.' + conditionIndex + '.field'}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":(val) => {
								_vm.changeField(val, groupIndex, conditionIndex)
							}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].field),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "field", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].field"}},_vm._l((_vm.fields),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)],1),(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].field == 'USER')?_c('div',[_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'groups.' + groupIndex + '.conditions.' + conditionIndex + '.compare'}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":(val) => {
									_vm.changeCompare(val, groupIndex, conditionIndex)
								}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].compare),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "compare", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].compare"}},_vm._l((_vm.user_compares),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)],1),_c('el-form-item',{staticStyle:{"position":"relative"},attrs:{"label-width":"0","prop":'groups.' + groupIndex + '.conditions.' + conditionIndex + '.value'}},[_c('el-input',{staticClass:"input-value",attrs:{"readonly":true},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].valueText),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "valueText", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].valueText"}}),_c('div',{staticClass:"add-value",on:{"click":function($event){return _vm.selectUser(groupIndex, conditionIndex)}}},[_vm._v("添加")])],1)],1):_c('div',[_c('el-form-item',{attrs:{"label":"","label-width":"0","prop":'groups.' + groupIndex + '.conditions.' + conditionIndex + '.compare'}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","filterable":""},on:{"change":(val) => {
									_vm.changeCompare(val, groupIndex, conditionIndex)
								}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].compare),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "compare", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].compare"}},_vm._l((_vm.compare[_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].field]),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)],1),_c('el-form-item',{staticStyle:{"position":"relative"},attrs:{"label-width":"0","prop":'groups.' + groupIndex + '.conditions.' + conditionIndex + '.value'}},[(_vm.isSelect(groupIndex, conditionIndex))?_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择","filterable":""},on:{"change":(val) => {
									_vm.changeSelectValue(val, groupIndex, conditionIndex)
								}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "value", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].value"}},_vm._l((_vm.value[_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].field]),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1):_vm._e(),(_vm.isInput(groupIndex, conditionIndex))?_c('el-input',{staticClass:"input-value",on:{"change":(n, o) => {
									_vm.changeInputValue(_vm.val, groupIndex, conditionIndex)
								}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "value", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].value"}}):_vm._e(),(_vm.isNumber(groupIndex, conditionIndex))?_c('el-input-number',{staticClass:"input-value",on:{"change":(n, o) => {
									_vm.changeInputValue(groupIndex, conditionIndex)
								}},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "value", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].value"}}):_vm._e(),(_vm.isDateTime(groupIndex, conditionIndex))?_c('el-date-picker',{attrs:{"type":"datetime","format":"yyyy-MM-dd HH:mm:ss","value-format":"yyyy-MM-dd HH:mm:ss","placeholder":"选择日期"},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "value", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].value"}}):_vm._e(),(_vm.isDate(groupIndex, conditionIndex))?_c('el-date-picker',{attrs:{"type":"date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","placeholder":"选择日期"},model:{value:(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex].value),callback:function ($$v) {_vm.$set(_vm.nodeProps.groups[groupIndex].conditions[conditionIndex], "value", $$v)},expression:"nodeProps.groups[groupIndex].conditions[conditionIndex].value"}}):_vm._e()],1)],1)],1)}),_c('div',{staticClass:"add-option",on:{"click":function($event){return _vm.addOption(groupIndex)}}},[_c('i',{staticClass:"el-icon-plus"}),_c('span',[_vm._v("添加条件")])])],2)])}),0),_c('div',{staticClass:"btm-add"},[_c('el-button',{staticStyle:{"width":"100%"},attrs:{"size":"small","icon":"el-icon-plus"},on:{"click":_vm.addGroup}},[_vm._v(" 添加条件组 ")])],1),_c('user-picker',{ref:"userPicker",attrs:{"selected":_vm.userPickerSelected},on:{"ok":_vm.selected}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }