<template>
	<div>
		<el-button size="mini" icon="el-icon-plus" type="primary" @click="showUserPicker" round>选择抄送人</el-button>
		<selected-items v-model="select" />
		<user-picker multiple ref="userPicker" :selected="userPickerSelected" @ok="selected" />
	</div>
</template>

<script>
import UserPicker from '../../components/UserPicker';
import SelectedItems from './SelectedItems';

export default {
	name: 'CcNodeConfig.vue',
	components: { UserPicker, SelectedItems },
	computed: {
		select: {
			get() {
				return this.$store.state.flowable.selectedNode.props.assignedUser || [];
			},
			set(val) {
				this.$store.state.flowable.selectedNode.props.assignedUser = val;
			}
		}
	},
	data() {
		return {
			userPickerSelected: []
		};
	},
	methods: {
		showUserPicker() {
			this.userPickerSelected = this.select;
			this.$refs.userPicker.show();
		},
		selected(select) {
			this.userPickerSelected.length = 0;
			select.forEach((val) => this.userPickerSelected.push(val));
		},
		removeOrgItem(index) {
			this.select.splice(index, 1);
		}
	}
};
</script>

<style lang="less" scoped>
.option {
	color: #606266;
	margin-top: 20px;
	font-size: small;
}

.desc {
	font-size: small;
	color: #8c8c8c;
}
.org-item {
	margin: 5px;
}
</style>
