<template>
	<div>
		<component :is="(selectNode.type || '').toLowerCase()" />
	</div>
</template>

<script>
import Approval from './ApprovalNodeConfig.vue';
import Exclusive from './ExclusiveNodeConfig.vue'
import Delay from './DelayNodeConfig.vue';
import Cc from './CcNodeConfig.vue';
import Root from './RootNodeConfig.vue';

export default {
	name: 'NodeConfig',
	components: {
		Root,
		Approval,
		Exclusive,
		Cc,
		Delay
	},
	data() {
		return {};
	},
	computed: {
		selectNode() {
			return this.$store.state.flowable.selectedNode;
		}
	},
	methods: {}
};
</script>

<style lang="less" scoped></style>
