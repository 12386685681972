<template>
	<el-container>
		<el-header style="background: white">
			<design-header v-model="activeSelect" @publish="publishProcess" @preview="preview"></design-header>
		</el-header>
		<div class="layout-body">
			<baseinfo-setting ref="baseinfo" v-show="activeSelect === 'baseinfo'" />
			<process-setting ref="process" v-show="activeSelect === 'process'" />
		</div>
		<w-dialog :showFooter="false" v-model="showValidDialog" title="设置项检查">
			<el-steps align-center :active="validStep" finish-status="success">
				<el-step v-for="(step, i) in validOptions" :title="step.title" :key="i" :icon="step.icon" :status="step.status" :description="step.description" />
			</el-steps>
			<el-result :icon="validIcon" :title="validTitle" :subTitle="validResult.desc">
				<i slot="icon" style="font-size: 30px" v-if="!validResult.finished" class="el-icon-loading"></i>
				<div slot="subTitle" class="err-info" v-if="validResult.errs.length > 0">
					<ellipsis hover-tip v-for="(err, i) in validResult.errs" :key="i + '_err'" :content="err">
						<i slot="pre" class="el-icon-warning-outline"></i>
					</ellipsis>
				</div>
				<template slot="extra">
					<el-button type="primary" v-if="validResult.finished" size="medium" @click="doAfter">
						{{ validResult.action }}
					</el-button>
				</template>
			</el-result>
		</w-dialog>
	</el-container>
</template>

<script>
import DesignHeader from './setting/DesignHeader';
import BaseinfoSetting from './setting/BaseinfoSetting';
import ProcessSetting from './setting/ProcessSetting';

export default {
	name: 'Design',
	components: { DesignHeader, BaseinfoSetting, ProcessSetting },
	data() {
		return {
			isNew: true,
			validStep: 0,
			timer: null,
			activeSelect: 'baseinfo',
			showValidDialog: false,
			validResult: {},
			validOptions: [
				{ title: '模板设置', description: '', icon: '', status: '' },
				{ title: '流程设置', description: '', icon: '', status: '' }
			],
			validComponents: ['baseinfo', 'process'],
		};
	},
	computed: {
		template() {
			return this.$store.state.flowable.template;
		},
		validTitle() {
			if (this.validResult.finished && !this.validResult.success) {
				return this.validResult.title + ` (${this.validResult.errs.length}项错误) 😥`;
			}
			return this.validResult.title;
		},
		validIcon() {
			if (!this.validResult.finished) {
				return 'el-icon-loading';
			} else if (this.validResult.success) {
				return 'success';
			} else {
				return 'warning';
			}
		}
	},
	created() {
		let templateId = this.$route.query.templateId;
		this.resetValidInfo();
		//判断传参，决定是新建还是加载原始数据
		this.loadAndInitTemplate();
		if (this.$utils.isNotEmpty(templateId)) {
			this.isNew = false;
			this.getTemplateInfo(templateId);
		}
		let groupId = this.$route.query.groupId;
		this.template.groupId = this.$utils.isNotEmpty(groupId) ? parseInt(groupId) : null;
	},
	beforeDestroy() {
		this.stopTimer();
	},
	methods: {
		async getTemplateInfo(templateId) {
			let template = await this.$api.flowable.templateInfo(templateId)
			if (template.process) {
				template.process = JSON.parse(template.process)
			} else {
				template.process = {
					id: 'root',
					parentId: null,
					type: 'ROOT',
					name: '发起人',
					desc: '任何人',
					props: {
						assignedUser: [],
					},
					child: {}
				}
			}
			this.$store.commit('loadTemplate', template);
		},
		loadAndInitTemplate() {
			this.$store.commit('loadTemplate', {
				id: null,
				name: '未命名表单',
				groupId: undefined,
				noticeType: [],
				remark: '备注说明',
				process: {
					id: 'root',
					parentId: null,
					type: 'ROOT',
					name: '发起人',
					desc: '任何人',
					props: {
						assignedUser: [],
					},
					child: {}
				},
				sign: false
			});
		},
		validateDesign() {
			this.showValidDialog = true;
			this.validStep = 0;
			this.resetValidInfo();
			this.stopTimer();
			this.timer = setInterval(() => {
				this.validResult.errs = this.$refs[this.validComponents[this.validStep]].validate();
				if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
					this.validStep++;
					if (this.validStep >= this.validOptions.length) {
						this.stopTimer();
						this.showValidFinish(true);
					}
				} else {
					this.stopTimer();
					this.validOptions[this.validStep].status = 'error';
					this.showValidFinish(false, this.getDefaultValidErr());
				}
			}, 300);
		},
		getDefaultValidErr() {
			switch (this.validStep) {
				case 0:
					return '请检查基础设置项';
				case 1:
					return '请检查审批表单相关设置';
				case 2:
					return '请检查审批流程，查看对应标注节点错误信息';
				case 3:
					return '请检查扩展设置';
				default:
					return '未知错误';
			}
		},
		showValidFinish(success, err) {
			this.validResult.success = success;
			this.validResult.finished = true;
			this.validResult.title = success ? '校验完成 😀' : '校验失败 ';
			this.validResult.desc = success ? '设置项校验成功，是否提交？' : err;
			this.validResult.action = success ? '提 交' : '去修改';
		},
		resetValidInfo() {
			this.validResult = {
				errs: [],
				finished: false,
				success: false,
				title: '检查中...',
				action: '处理',
				desc: '正在检查设置项'
			};
			this.validStep = 0;
			this.validOptions.forEach((op) => {
				op.status = '';
				op.icon = '';
				op.description = '';
			});
		},
		doAfter() {
			if (this.validResult.success) {
				this.doPublish();
			} else {
				this.activeSelect = this.validComponents[this.validStep];
				this.showValidDialog = false;
			}
		},
		stopTimer() {
			if (this.timer) {
				clearInterval(this.timer);
			}
		},
		preview() {
			this.validateDesign();
		},
		publishProcess() {
			this.validateDesign();
		},
		async doPublish() {
			this.$confirm('如果您只想预览请选择预览，确认发布后流程立即生效，是否继续?', '提示', {
				confirmButtonText: '发布',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let noticeTypes = this.template.noticeType || []
				let process = JSON.parse(JSON.stringify(this.template.process));
				//判断条件分支
				let template = {
					id: this.template.id,
					name: this.template.name,
					groupId: this.template.groupId,
					noticeType: noticeTypes.join(','),
					remark: this.template.remark,
					process: JSON.stringify(process),
					sign: this.template.sign
				};
				this.$api.flowable.updateTemplate(template, this.isNew ? 'post' : 'put')
				.then(() => {
					this.$message.success(this.isNew ? '创建模板成功' : '更新模板成功');
					this.$router.push(`/groups`);
				})
			});
		},
	}
};
</script>

<style lang="less" scoped>
.layout-body {
	min-width: 980px;
}

/deep/ .el-step {
	.is-success {
		color: #2a99ff;
		border-color: #2a99ff;
	}
}

.err-info {
	max-height: 180px;
	overflow-y: auto;
	& > div {
		padding: 5px;
		margin: 2px 0;
		width: 220px;
		text-align: left;
		border-radius: 3px;
		background: rgb(242 242 242);
	}
	i {
		margin: 0 5px;
	}
}

::-webkit-scrollbar {
	width: 2px;
	height: 2px;
	background-color: white;
}

::-webkit-scrollbar-thumb {
	border-radius: 16px;
	background-color: #e8e8e8;
}
</style>
