<template>
	<div>
		<div class="top-tip">满足以下条件时进入当前分支</div>
		<el-form :model="nodeProps">
			<div class="groups-item" v-for="(group, groupIndex) in nodeProps.groups" :key="groupIndex">
				<div v-if="groupIndex !== 0" style="padding: 15px 0; color: #646a73">
					或满足
				</div>
				<div class="groups-main">
					<div class="groups-title">
						<span>条件组{{ groupIndex + 1 }}</span>
						<i class="el-icon-delete" @click="deleteGroup(groupIndex)"></i>
					</div>
					<div class="options-item" v-for="(condition, conditionIndex) in group.conditions" :key="`${groupIndex}-${conditionIndex}`">
						<div class="options-title">
							<span>{{ conditionIndex === 0 ? '当' : '且' }}</span>
							<i class="el-icon-delete" color="#9ca2a9" @click="deleteOption(groupIndex, conditionIndex)"></i>
						</div>
						<el-form-item
							label=""
							label-width="0"
							:prop="'groups.' + groupIndex + '.conditions.' + conditionIndex + '.field'">
							<el-select
								style="width: 100%"
								v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].field"
								placeholder="请选择"
								@change="(val) => {
									changeField(val, groupIndex, conditionIndex)
								}"
							>
								<el-option v-for="option in fields" :value="option.value" :label="option.label" :key="option.value" />
							</el-select>
						</el-form-item>
						<div v-if="nodeProps.groups[groupIndex].conditions[conditionIndex].field == 'USER'">
							<el-form-item
								label=""
								label-width="0"
								:prop="'groups.' + groupIndex + '.conditions.' + conditionIndex + '.compare'"
							>
								<el-select
									style="width: 100%"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].compare"
									placeholder="请选择"
									@change="(val) => {
										changeCompare(val, groupIndex, conditionIndex)
									}"
								>
									<el-option v-for="option in user_compares" :value="option.value" :label="option.label" :key="option.value" />
								</el-select>
							</el-form-item>
							<el-form-item
								label-width="0"
								:prop="'groups.' + groupIndex + '.conditions.' + conditionIndex + '.value'"
								style="position: relative"
							>
								<el-input
									class="input-value"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].valueText"
									:readonly="true"
								/>
								<div class="add-value" @click="selectUser(groupIndex, conditionIndex)">添加</div>
							</el-form-item>
						</div>
						<div v-else>
							<el-form-item
								label=""
								label-width="0"
								:prop="'groups.' + groupIndex + '.conditions.' + conditionIndex + '.compare'"
							>
								<el-select
									style="width: 100%"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].compare"
									placeholder="请选择"
									filterable
									@change="(val) => {
										changeCompare(val, groupIndex, conditionIndex)
									}"
								>
									<el-option v-for="option in compare[nodeProps.groups[groupIndex].conditions[conditionIndex].field]" :value="option.value" :label="option.label" :key="option.value" />
								</el-select>
							</el-form-item>
							<el-form-item
								label-width="0"
								:prop="'groups.' + groupIndex + '.conditions.' + conditionIndex + '.value'"
								style="position: relative"
							>
								<el-select v-if="isSelect(groupIndex, conditionIndex)"
									style="width: 100%"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
									placeholder="请选择"
									filterable
									@change="(val) => {
										changeSelectValue(val, groupIndex, conditionIndex)
									}"
								>
									<el-option v-for="option in value[nodeProps.groups[groupIndex].conditions[conditionIndex].field]" :value="option.value" :label="option.label" :key="option.value" />
								</el-select>
								<el-input v-if="isInput(groupIndex, conditionIndex)"
									class="input-value"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
									@change="(n, o) => {
										changeInputValue(val, groupIndex, conditionIndex)
									}"
								/>
								<el-input-number v-if="isNumber(groupIndex, conditionIndex)"
									class="input-value"
									v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
									@change="(n, o) => {
										changeInputValue(groupIndex, conditionIndex)
									}"
								/>
								<el-date-picker v-if="isDateTime(groupIndex, conditionIndex)"
								    v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
								    type="datetime"
									format="yyyy-MM-dd HH:mm:ss"
									value-format="yyyy-MM-dd HH:mm:ss"
									placeholder="选择日期">
								</el-date-picker>
								<el-date-picker v-if="isDate(groupIndex, conditionIndex)"
								    v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
								    type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									placeholder="选择日期">
								</el-date-picker>
								<el-time-picker v-if="isTime(groupIndex, conditionIndex)"
								    v-model="nodeProps.groups[groupIndex].conditions[conditionIndex].value"
									placeholder="选择时间"
								  </el-time-picker>
							</el-form-item>
						</div>
					</div>
					<div class="add-option" @click="addOption(groupIndex)">
						<i class="el-icon-plus"></i>
						<span>添加条件</span>
					</div>
				</div>
			</div>
		</el-form>
		<div class="btm-add">
			<el-button
				size="small"
				icon="el-icon-plus"
				style="width: 100%"
				@click="addGroup"
			>
				添加条件组
			</el-button>
		</div>
		<user-picker ref="userPicker" :selected="userPickerSelected" @ok="selected" />
	</div>
</template>

<script>
import UserPicker from '../../components/UserPicker';
	
const CONDITION = { 
	field: '', 
	fieldText: '', 
	compare: '', 
	compareText: '', 
	value: '', 
	valueText: '' ,
}
export default {
	name: 'ConditionNodeConfig',
	components: { UserPicker },
	data() {
		return {
			userPickerSelected: [],
			fields: [
				{
					value: 'USER',
					label: '发起人'
				}
			],
			user_compares: [
				{
					value: '=',
					label: '是'
				},
				{
					value: '!=',
					label: '不是'
				}
			],
			compare: {},
			value: {},
			changeOptionValue: {
				groupIndex: 0,
				conditionIndex: 0
			}
		}
	},
	computed: {
		templateId() {
			return this.$store.state.flowable.template.id;
		},
		nodeProps() {
			return this.$store.state.flowable.selectedNode.props;
		},
		select() {
			return this.$store.state.flowable.selectedNode.props.assignedUser || [];
		},
	},
	created() {
		this.getConditionConfig()
	},
	methods: {
		async getConditionConfig() {
			const { fields, compare, value } = await this.$api.flowable.getConditionConfig({
				templateId: this.templateId
			})
			this.fields.push(...fields)
			this.compare = compare
			this.value = value
		},
		addGroup() {
			this.nodeProps.groups.push({
				name: '条件组',
				conditions: [{ ...CONDITION }]
			})
		},
		changeField(val, groupIndex, conditionIndex) {
			let field = this.fields.find(item => item.value == val)
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].fieldText = field.label
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].compare = ''
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].compareText = ''
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].value = ''
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].valueText = ''
		},
		isSelect(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'SELECT'
		},
		isInput(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'INPUT'
		},
		isNumber(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'NUMBER'
		},
		isDate(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'DATE'
		},
		isTime(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'TIME'
		},
		isDateTime(groupIndex, conditionIndex) {
			return this.getComponent(groupIndex, conditionIndex) === 'DATETIME'
		},
		getComponent(groupIndex, conditionIndex) {
			let val = this.nodeProps.groups[groupIndex].conditions[conditionIndex].field
			if (val) {
				return this.fields.find(item => item.value == val)?.component
			}
			return ''
		},
		changeCompare(val, groupIndex, conditionIndex) {
			const field = this.nodeProps.groups[groupIndex].conditions[conditionIndex].field
			if (field == 'USER') {
				let compare = this.user_compares.find(item => item.value == val)
				this.nodeProps.groups[groupIndex].conditions[conditionIndex].compareText = compare.label
			} else {
				let compare = this.compare[field].find(item => item.value == val)
				this.nodeProps.groups[groupIndex].conditions[conditionIndex].compareText = compare.label
			}
		},
		changeSelectValue(val, groupIndex, conditionIndex) {
			const field = this.nodeProps.groups[groupIndex].conditions[conditionIndex].field
			let value = this.value[field].find(item => item.value == val)
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].valueText = value.label
		},
		changeInputValue(groupIndex, conditionIndex) {
			this.nodeProps.groups[groupIndex].conditions[conditionIndex].valueText = this.nodeProps.groups[groupIndex].conditions[conditionIndex].value
		},
		selectUser(groupIndex, conditionIndex) {
			this.userPickerSelected = this.select;
			this.changeOptionValue = {
				groupIndex: groupIndex,
				conditionIndex: conditionIndex
			}
			this.$refs.userPicker.show();
		},
		selected(select) {
			this.userPickerSelected.length = 0;
			select.forEach((val) => this.userPickerSelected.push(val));
			if (this.userPickerSelected.length > 0) {
				const groupIndex = this.changeOptionValue.groupIndex
				const conditionIndex = this.changeOptionValue.conditionIndex
				this.nodeProps.groups[groupIndex].conditions[conditionIndex].value = this.userPickerSelected[0].id
				this.nodeProps.groups[groupIndex].conditions[conditionIndex].valueText = this.userPickerSelected[0].nickname
			}
		},
		deleteGroup(groupIndex) {
			let groups = this.$utils.deepClone(this.nodeProps.groups)
			groups.splice(groupIndex, 1)
			this.$set(this.nodeProps, 'groups', groups)
		},
		addOption(groupIndex) {
			this.nodeProps.groups[groupIndex].conditions.push({ ...CONDITION })
		},
		deleteOption(groupIndex, conditionIndex) {
			let group = this.$utils.deepClone(this.nodeProps.groups[groupIndex])
			group.conditions.splice(conditionIndex, 1)
			this.$set(this.nodeProps.groups, groupIndex, group)
		}
	}
}
</script>

<style lang="less" scoped>
.top-tip {
	color: #646a73;
	margin-bottom: 12px;
}
.el-icon-delete {
	width: 2em;
	height: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover {
		color: #009688;
	}
}
.groups-item {
	.groups-main {
		border-radius: 4px;
		border: 1px solid #e4e5e7;
	}
	.groups-title {
		display: flex;
		align-items: center;
		color: #171e31;
		background-color: #f4f6f8;
		padding: 0 12px;
		color: #171e31;
		height: 36px;
		span {
			flex: 1;
		}
	}
	.add-option {
		color: #009688;
		padding: 12px;
		cursor: pointer;
		.el-icon-plus {
			color: #009688;
		}
	}
}
.options-item {
	padding: 0 12px;
	.options-title {
		display: flex;
		align-items: center;
		height: 36px;
		color: #9ca2a9;
		span {
			flex: 1;
		}
	}
	.input-value {
		/deep/ .el-input__inner {
			cursor: inherit;
		}
	}
	.add-value {
		position: absolute;
		right: 0;
		top: 0;
		color: #009688;
		padding: 0 12px;
		cursor: pointer;
		z-index: 1;
	}
}
.btm-add {
	margin-top: 20px;
}
/deep/ .el-form-item {
	margin-bottom: 10px;
}
</style>
