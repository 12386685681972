<template>
	<div>
		<div class="header">
			<el-menu
				:default-active="value"
				active-text-color="#409eff"
				class="el-menu-demo"
				mode="horizontal"
			>
				<el-menu-item index="template" @click="changeTab('baseinfo')">
					① 模板设置
				</el-menu-item>
				<el-menu-item index="process" @click="changeTab('process')">
					② 流程设置
				</el-menu-item>
			</el-menu>
			<div class="publish">
				<el-button size="mini" type="primary" @click="publish">
					<i class="el-icon-s-promotion"></i>
					发布
				</el-button>
			</div>
			<div class="back">
				<el-button @click="exit" size="medium" icon="el-icon-arrow-left" circle></el-button>
				<span style="margin-left: 10px;">{{ template.name }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DesignHeader',
	props: {
		value: {
			type: String,
			default: 'baseinfo'
		}
	},
	data() {
		return {}
	},
	computed: {
		template() {
			return this.$store.state.flowable.template
		}
	},
	created() {},
	mounted() {
		if (document.body.offsetWidth <= 970) {
			this.$msgbox.alert(
				'本设计器未适配中小屏幕，建议您在PC电脑端浏览器进行操作'
			)
		}
		this.listener()
	},
	methods: {
		publish() {
			this.$emit('publish')
		},
		preview() {
			this.$emit('preview')
		},
		changeTab(path) {
			this.$emit('input', path)
		},
		listener() {
			window.onunload = this.closeBefore()
			window.onbeforeunload = this.closeBefore()
		},
		closeBefore() {
			return false
		},
		exit() {
			this.$confirm('未发布的内容将不会被保存，是否直接退出 ?', '提示', {
				confirmButtonText: '退出',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$router.push('/groups')
			})
		},
	}
}
</script>
<style lang="less" scoped>
.header {
	min-width: 980px;
	position: relative;
	.el-menu {
		top: 0;
		z-index: 999;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.publish {
		position: absolute;
		top: 15px;
		right: 20px;
		z-index: 1000;
		i {
			margin-right: 6px;
		}
		button {
			border-radius: 15px;
		}
	}
	.back {
	    position: absolute;
	    z-index: 1000;
	    top: 10px;
	    left: 20px;
	    font-size: small;
	  }
}
</style>
