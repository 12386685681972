<template>
	<div>
		<el-form label-position="top" label-width="90px">
			<el-form-item label="⚙ 选择审批对象" prop="text" class="user-type">
				<el-radio-group size="mini" v-model="nodeProps.assignedType" @input="changeAssignedType">
					<el-radio v-for="approval in approvalTypes" :label="approval.value" :key="approval.value">{{ approval.name }}</el-radio>
				</el-radio-group>
				<div v-if="nodeProps.assignedType === 'ASSIGN_USER'">
					<el-button size="mini" icon="el-icon-plus" type="primary" @click="selectUser" round>选择人员</el-button>
					<selected-items v-model="nodeProps.assignedUser" />
				</div>
				<div v-else-if="nodeProps.assignedType === 'SELF_SELECT'">
					<el-button size="mini" icon="el-icon-plus" type="primary" @click="selectUser" round>选择人员</el-button>
					<selected-items v-model="nodeProps.assignedUser" />
					<el-radio-group size="mini" v-model="nodeProps.selfSelect.multiple">
						<el-radio-button :label="false">自选一个人</el-radio-button>
						<el-radio-button :label="true">自选多个人</el-radio-button>
					</el-radio-group>
				</div>
				<div v-else>
					<span class="item-desc">发起人自己作为审批人进行审批</span>
				</div>
			</el-form-item>
			<!-- <div v-if="nodeProps.assignedType === 'SELF_SELECT'">
				<el-divider />
				<el-form-item label="👤 审批人为空时" prop="text" class="approve-mode">
					<el-radio-group size="mini" v-model="nodeProps.nobody.handler">
						<el-radio label="TO_PASS">自动通过</el-radio>
						<el-radio label="TO_REFUSE">自动驳回</el-radio>
						<el-radio label="TO_USER">转交到指定人员</el-radio>
					</el-radio-group>
					<div style="margin-top: 10px" v-if="nodeProps.nobody.handler === 'TO_USER'">
						<el-button size="mini" icon="el-icon-plus" type="primary" @click="selectNoSetUser" round>选择人员</el-button>
						<selected-items v-model="nodeProps.nobody.assignedUser" />
					</div>
				</el-form-item>
			</div> -->
			<div v-if="showMode">
				<el-divider />
				<el-form-item label="👩‍👦‍👦 多人审批时审批方式" prop="text" class="approve-mode">
					<el-radio-group size="mini" v-model="nodeProps.mode">
						<el-radio label="ORDERLY">会签 （按选择顺序审批，每个人必须同意）</el-radio>
						<el-radio label="DISORDER">会签（可同时审批，每个人必须同意）</el-radio>
						<el-radio label="OR">或签（有一人同意即可）</el-radio>
					</el-radio-group>
				</el-form-item>
			</div>
			<!-- <el-divider />
			<el-form-item label="✍ 审批同意时是否需要签字" prop="text" v-if="!template.sign">
				<el-switch size="mini" inactive-text="不用" active-text="需要" v-model="nodeProps.sign"></el-switch>
				<el-tooltip class="item" effect="dark" content="如果全局设置了需要签字，则此处不生效" placement="top-start">
					<i class="el-icon-question" style="margin-left: 10px; font-size: medium; color: #b0b0b1"></i>
				</el-tooltip>
			</el-form-item>
			<el-divider />
			<el-form-item label="⏱ 审批通过后是否配置异步任务">
				<el-switch size="mini" inactive-text="否" active-text="是" v-model="nodeProps.asyncTask.async"></el-switch>
				<el-tooltip class="item" effect="dark" content="异步任务配置,需业务侧实现,配置前务必和业务侧沟通确认" placement="top-start">
					<i class="el-icon-question" style="margin-left: 10px; font-size: medium; color: #b0b0b1"></i>
				</el-tooltip>
			</el-form-item>
			<el-form-item label="⏱ 任务执行频率" v-if='nodeProps.asyncTask.async'>
				<el-input-number size="mini" :min="1" v-model="nodeProps.asyncTask.interval" />
				<el-radio-group size="mini" style="margin-left: 10px;" v-model="nodeProps.asyncTask.unit">
					<el-radio label="date">天</el-radio>
					<el-radio label="hour">时</el-radio>
					<el-radio label="minute">分</el-radio>
					<el-radio label="second">秒</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if='nodeProps.asyncTask.async'>
				<el-input-number size="mini" :min="1" v-model="nodeProps.asyncTask.expired" />
				<span style="margin: auto 10px;">天后，任务将自动</span>
				<el-radio-group size="mini" v-model="nodeProps.asyncTask.handler">
					<el-radio label="TO_PASS">通过</el-radio>
					<el-radio label="TO_REFUSE">驳回</el-radio>
				</el-radio-group>
			</el-form-item> -->
		</el-form>
		<user-picker multiple ref="userPicker" :selected="userPickerSelected" @ok="selected" />
	</div>
</template>

<script>
import UserPicker from '../../components/UserPicker';
import SelectedItems from './SelectedItems';

export default {
	name: 'ApprovalNodeConfig',
	components: { UserPicker, SelectedItems },
	data() {
		return {
			showOrgSelect: false,
			userPickerSelected: [],
			approvalTypes: [
				{ name: '指定人员', value: 'ASSIGN_USER' },
				{ name: '发起人自选', value: 'SELF_SELECT' },
				{ name: '发起人自己', value: 'SELF' }
			],
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now();
				}
			},
		};
	},
	computed: {
		template() {
			return this.$store.state.flowable.template;
		},
		nodeProps() {
			return this.$store.state.flowable.selectedNode.props;
		},
		select() {
			return this.$store.state.flowable.selectedNode.props.assignedUser || [];
		},
		nodeOptions() {
			let values = [];
			const excType = ['ROOT', 'EMPTY', 'EXCLUSIVE', 'EXCLUSIVES', 'PARALLEL', 'PARALLELS'];
			this.$store.state.flowable.nodeMap.forEach((v) => {
				if (excType.indexOf(v.type) === -1) {
					values.push({ id: v.id, name: v.name });
				}
			});
			return values;
		},
		showMode() {
			switch (this.nodeProps.assignedType) {
				case 'ASSIGN_USER':
					return this.nodeProps.assignedUser.length > 1;
				case 'SELF_SELECT':
					return true;
				default:
					return false;
			}
		}
	},
	methods: {
		selectUser() {
			this.userPickerSelected = this.select;
			this.$refs.userPicker.show();
		},
		selectNoSetUser() {
			this.userPickerSelected = this.nodeProps.nobody.assignedUser;
			this.$refs.userPicker.show();
		},
		selected(select) {
			this.userPickerSelected.length = 0;
			select.forEach((val) => this.userPickerSelected.push(val));
		},
		changeAssignedType(val) {
			this.$store.state.flowable.selectedNode.props.assignedUser = []
		}
	}
};
</script>

<style lang="less" scoped>
.user-type {
	/deep/ .el-radio {
		width: 110px;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

/deep/ .line-mode {
	.el-radio {
		width: 150px;
		margin: 5px;
	}
}
/deep/ .el-form-item {
	margin-bottom: 15px;
}
/deep/ .el-form-item__label {
	line-height: 25px;
	padding: 0;
}

/deep/ .approve-mode {
	.el-radio {
		float: left;
		width: 100%;
		display: block;
		margin-top: 15px;
	}
}

/deep/ .approve-end {
	position: relative;

	.el-radio-group {
		width: 160px;
	}

	.el-radio {
		margin-bottom: 5px;
		width: 100%;
	}

	.approve-end-leave {
		position: absolute;
		bottom: -5px;
		left: 150px;
	}
}

/deep/ .el-divider--horizontal {
	margin: 10px 0;
}
</style>
