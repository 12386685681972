<template>
	<div class="base-setup">
		<el-form ref="baseInfo" :model="template" label-position="top" label-width="80px">
			<el-form-item label="模板名称" :rules="requiredRule('请输入流程名称')" prop="name">
				<el-input v-model="template.name" size="medium"></el-input>
			</el-form-item>
			<el-form-item label="所在分组" :rules="requiredRule('请选择表单分组')" class="group" prop="groupId">
				<el-select v-model="template.groupId" placeholder="请选择分组" size="medium">
					<el-option v-for="(group, index) in groups" :key="index" :label="group.name" :value="group.id"></el-option>
				</el-select>
				<el-popover placement="bottom-end" title="新建表单分组" width="300" trigger="click">
					<el-input size="medium" v-model="groupName" placeholder="请输入新的分组名">
						<el-button slot="append" size="medium" type="primary" @click="addGroup">提交</el-button>
					</el-input>
					<el-button icon="el-icon-plus" slot="reference" size="medium" type="primary">新建分组</el-button>
				</el-popover>
			</el-form-item>
			<el-form-item label="消息通知方式" :rules="requiredRule('请选择消息通知方式')">
				<el-select v-model="template.noticeType" placeholder="选择消息通知方式" clearable multiple collapse-tags>
					<el-option v-for="(notice, index) in noticeTypes" :label="notice.label" :key="index" :value="notice.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="模板描述">
				<el-input placeholder="请输入描述" v-model="template.remark" type="textarea" show-word-limit :autosize="{ minRows: 2, maxRows: 5 }" maxlength="500"></el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'BaseinfoSetting',
	data() {
		return {
			groupName: '',
			groups: [],
			noticeTypes: []
		};
	},
	computed: {
		template() {
			return this.$store.state.flowable.template
		}
	},
	created() {},
	mounted() {
		this.listGroups()
		this.listNoticeTypes()
	},
	methods: {
		requiredRule(msg) {
			return [{ required: true, message: msg, trigger: 'blur' }];
		},
		async listNoticeTypes() {
			this.noticeTypes = await this.$api.flowable.listNoticeTypes()
		},
		async listGroups() {
			this.groups = await this.$api.flowable.listGroups()
		},
		async addGroup() {
			if (this.groupName.trim() !== '') {
				await this.$api.flowable.updateGroup({ name: this.groupName.trim() }, 'post')
				this.listGroups();
			}
		},
		validate() {
			this.$refs.baseInfo.validate();
			let err = [];
			if (!this.$utils.isNotEmpty(this.template.name)) {
				err.push('表单名称未设置');
			}
			if (!this.$utils.isNotEmpty(this.template.groupId)) {
				err.push('表单分组未设置');
			}
			return err;
		}
	}
};
</script>

<style lang="less" scoped>
/deep/ .el-select-dropdown {
	display: none;
}

.icon-select {
	display: flex;
	flex-wrap: wrap;
	i {
		cursor: pointer;
		font-size: large;
		padding: 10px;
		max-width: 38px !important;
		&:hover {
			box-shadow: 0 0 10px 2px #c2c2c2;
		}
	}
}

/deep/ .select-u {
	width: 100%;
}

.base-setup {
	overflow: auto;
	margin: 0 auto;
	height: calc(100vh - 105px);
	background: #ffffff;
	margin-top: 10px;
	padding: 15px 25%;

	i:first-child {
		position: relative;
		cursor: pointer;
		font-size: xx-large;
		color: #ffffff;
		border-radius: 10px;
		padding: 10px;
	}

	.change-icon {
		margin-left: 20px;

		span {
			font-size: small;
			color: #7a7a7a;
			margin-right: 15px;
		}

		i {
			cursor: pointer;
			color: #7a7a7a;
			font-size: x-large;
		}
	}

	/deep/ .el-form-item__label {
		padding: 0;
		font-weight: bold;
	}

	/deep/ .el-form-item {
		margin-bottom: 5px;
	}
}

/deep/ .group {
	.el-select {
		width: calc(100% - 130px);
	}

	.el-button {
		margin-left: 10px;
		width: 120px;
	}
}
.sign-tip {
	display: inline-block;
	color: #949495;
	font-size: 12px;
	line-height: 20px;
	height: 20px;
	margin-left: 20px;
}
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
	border-radius: 16px;
	background-color: #e8e8e8;
}
</style>
