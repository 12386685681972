<script>
//导入所有节点组件
import Approval from './nodes/ApprovalNode.vue';
import Cc from './nodes/CcNode.vue';
import Paraallel from './nodes/ParallelNode.vue';
import Exclusive from './nodes/ExclusiveNode.vue';
import Trigger from './nodes/TriggerNode.vue';
import Delay from './nodes/DelayNode.vue';
import Empty from './nodes/EmptyNode.vue';
import Root from './nodes/RootNode.vue';
import Node from './nodes/Node.vue';

import Constant from './Constant';

export default {
	name: 'ProcessDesign',
	components: { Node, Root, Approval, Cc, Trigger, Paraallel, Exclusive, Delay, Empty },
	data() {
		return {
			valid: true
		};
	},
	computed: {
		nodeMap() {
			return this.$store.state.flowable.nodeMap;
		},
		nodeInfo() {
			return this.$store.state.flowable.template.process;
		}
	},
	render(h, ctx) {
		this.nodeMap.clear();
		let dom = this.getDomTree(h, this.nodeInfo);
		//插入末端节点
		dom.push(h('div', { style: { 'text-align': 'center' } }, [h('div', { class: { 'process-end': true }, domProps: { innerHTML: '流程结束' } })]));
		return h('div', { class: { _root: true }, ref: '_root' }, dom);
	},
	methods: {
		getDomTree(h, node) {
			this.toMapping(node);
			if (this.isPrimaryNode(node)) {
				//普通业务节点
				let childDoms = this.getDomTree(h, node.child);
				this.decodeAppendDom(h, node, childDoms);
				return [h('div', { class: { 'primary-node': true } }, childDoms)];
			} else if (this.isBranchNode(node)) {
				let index = 0;
				//遍历分支节点，包含并行及条件节点
				let branchItems = node.branches.map((branchNode) => {
					//处理每个分支内子节点
					this.toMapping(branchNode);
					let childDoms = this.getDomTree(h, branchNode.child);
					this.decodeAppendDom(h, branchNode, childDoms, { level: index + 1, size: node.branches.length });
					//插入4条横线，遮挡掉条件节点左右半边线条
					this.insertCoverLine(h, index, childDoms, node.branches);
					//遍历子分支尾部分支
					index++;
					return h('div', { class: { 'branch-node-item': true } }, childDoms);
				});
				//插入添加分支/条件的按钮
				branchItems.unshift(
					h('div', { class: { 'add-branch-btn': true } }, [
						h(
							'el-button',
							{
								class: { 'add-branch-btn-el': true },
								props: { size: 'small', round: true },
								on: { click: () => this.addBranchNode(node) },
								domProps: { innerHTML: `添加${this.isExclusiveNode(node) ? '条件' : '分支'}` }
							},
							[]
						)
					])
				);
				let bchDom = [h('div', { class: { 'branch-node': true } }, branchItems)];
				//继续遍历分支后的节点
				let afterChildDoms = this.getDomTree(h, node.child);
				return [h('div', {}, [bchDom, afterChildDoms])];
			} else if (this.isEmptyNode(node)) {
				//空节点，存在于分支尾部
				let childDoms = this.getDomTree(h, node.child);
				this.decodeAppendDom(h, node, childDoms);
				return [h('div', { class: { 'empty-node': true } }, childDoms)];
			} else {
				//遍历到了末端，无子节点
				return [];
			}
		},
		//解码渲染的时候插入dom到同级
		decodeAppendDom(h, node, doms, props = {}) {
			props.nodeInfo = node;
			doms.unshift(
				h(
					node.type.toLowerCase(),
					{
						props: props,
						ref: node.id,
						key: node.id,
						//定义事件，插入节点，删除节点，选中节点，复制/移动
						on: {
							insertNode: (type) => this.insertNode(type, node),
							delNode: () => this.delNode(node),
							selected: () => this.selectNode(props),
							copy: () => this.copyBranch(node),
							leftMove: () => this.branchMove(node, -1),
							rightMove: () => this.branchMove(node, 1)
						}
					},
					[]
				)
			);
		},
		//id映射到map，用来向上遍历
		toMapping(node) {
			if (node && node.id) {
				this.nodeMap.set(node.id, node);
			}
		},
		insertCoverLine(h, index, doms, branches) {
			if (index === 0) {
				//最左侧分支
				doms.unshift(h('div', { class: { 'line-top-left': true } }, []));
				doms.unshift(h('div', { class: { 'line-bot-left': true } }, []));
			} else if (index === branches.length - 1) {
				//最右侧分支
				doms.unshift(h('div', { class: { 'line-top-right': true } }, []));
				doms.unshift(h('div', { class: { 'line-bot-right': true } }, []));
			}
		},
		copyBranch(node) {
			let parentNode = this.nodeMap.get(node.parentId);
			let branchNode = this.$utils.deepCopy(node);
			branchNode.name = branchNode.name + '-copy';
			this.forEachNode(parentNode, branchNode, (parent, node) => {
				let id = this.getRandomId();
				node.id = id;
				node.parentId = parent.id;
			});
			parentNode.branches.splice(parentNode.branches.indexOf(node), 0, branchNode);
			this.$forceUpdate();
		},
		branchMove(node, offset) {
			let parentNode = this.nodeMap.get(node.parentId);
			let index = parentNode.branches.indexOf(node);
			let branch = parentNode.branches[index + offset];
			parentNode.branches[index + offset] = parentNode.branches[index];
			parentNode.branches[index] = branch;
			this.$forceUpdate();
		},
		//判断是否为主要业务节点
		isPrimaryNode(node) {
			return node && (node.type === 'ROOT' || node.type === 'APPROVAL' || node.type === 'CC' || node.type === 'DELAY' || node.type === 'TRIGGER');
		},
		isBranchNode(node) {
			return node && (node.type === 'EXCLUSIVES' || node.type === 'PARALLELS');
		},
		isEmptyNode(node) {
			return node && node.type === 'EMPTY';
		},
		//是分支节点
		isExclusiveNode(node) {
			return node.type === 'EXCLUSIVES';
		},
		//是分支节点
		isBranchSubNode(node) {
			return node && (node.type === 'EXCLUSIVE' || node.type === 'PARALLEL');
		},
		isParallelNode(node) {
			return node.type === 'PARALLELS';
		},
		getRandomId() {
			return `node_${new Date().getTime().toString().substring(5)}${Math.round(Math.random() * 9000 + 1000)}`;
		},
		//选中一个节点
		selectNode(props) {
			this.$store.commit('selectedNode', props.nodeInfo);
			this.$emit('selectedNode', props);
		},
		//处理节点插入逻辑
		insertNode(type, parentNode) {
			this.$refs['_root'].click();
			//缓存一下后面的节点
			let afterNode = parentNode.child;
			//插入新节点
			parentNode.child = {
				id: this.getRandomId(),
				parentId: parentNode.id,
				type: type,
				props: {}
			};
			switch (type) {
				case 'APPROVAL':
					this.insertApprovalNode(parentNode, afterNode);
					break;
				case 'CC':
					this.insertCcNode(parentNode);
					break;
				case 'DELAY':
					this.insertDelayNode(parentNode);
					break;
				case 'TRIGGER':
					this.insertTriggerNode(parentNode);
					break;
				case 'EXCLUSIVES':
					this.isExclusivesNode(parentNode);
					break;
				case 'PARALLELS':
					this.isParallelsNode(parentNode);
					break;
				default:
					break;
			}
			//拼接后续节点
			if (this.isBranchNode({ type: type })) {
				if (afterNode && afterNode.id) {
					afterNode.parentId = parentNode.child.child.id;
				}
				this.$set(parentNode.child.child, 'child', afterNode);
			} else {
				if (afterNode && afterNode.id) {
					afterNode.parentId = parentNode.child.id;
				}
				this.$set(parentNode.child, 'child', afterNode);
			}
			this.$forceUpdate();
		},
		insertApprovalNode(parentNode) {
			this.$set(parentNode.child, 'name', '审批人');
			this.$set(parentNode.child, 'props', this.$utils.deepCopy(Constant.APPROVAL_PROPS));
		},
		insertCcNode(parentNode) {
			this.$set(parentNode.child, 'name', '抄送人');
			this.$set(parentNode.child, 'props', this.$utils.deepCopy(Constant.CC_PROPS));
		},
		insertDelayNode(parentNode) {
			this.$set(parentNode.child, 'name', '延时处理');
			this.$set(parentNode.child, 'props', this.$utils.deepCopy(Constant.DELAY_PROPS));
		},
		insertTriggerNode(parentNode) {
			this.$set(parentNode.child, 'name', '触发器');
			this.$set(parentNode.child, 'props', this.$utils.deepCopy(Constant.TRIGGER_PROPS));
		},
		isExclusivesNode(parentNode) {
			this.$set(parentNode.child, 'name', '条件分支');
			this.$set(parentNode.child, 'child', {
				id: this.getRandomId(),
				parentId: parentNode.child.id,
				type: 'EMPTY'
			});
			this.$set(parentNode.child, 'branches', [
				{
					id: this.getRandomId(),
					parentId: parentNode.child.id,
					type: 'EXCLUSIVE',
					name: '条件1',
					props: this.$utils.deepCopy(Constant.EXCLUSIVE_PROPS),
					child: {}
				},
				{
					id: this.getRandomId(),
					parentId: parentNode.child.id,
					type: 'EXCLUSIVE',
					name: '条件2',
					props: this.$utils.deepCopy(Constant.EXCLUSIVE_PROPS),
					child: {}
				}
			]);
		},
		isParallelsNode(parentNode) {
			this.$set(parentNode.child, 'name', '并行分支');
			this.$set(parentNode.child, 'child', {
				id: this.getRandomId(),
				parentId: parentNode.child.id,
				type: 'EMPTY'
			});
			this.$set(parentNode.child, 'branches', [
				{
					id: this.getRandomId(),
					parentId: parentNode.child.id,
					type: 'PARALLEL',
					name: '分支1',
					props: {},
					child: {}
				},
				{
					id: this.getRandomId(),
					parentId: parentNode.child.id,
					type: 'PARALLEL',
					name: '分支2',
					props: {},
					child: {}
				}
			]);
		},
		getBranchEndNode(conditionNode) {
			if (!conditionNode.child || !conditionNode.child.id) {
				return conditionNode;
			}
			return this.getBranchEndNode(conditionNode.child);
		},
		addBranchNode(node) {
			if (node.branches.length < 8) {
				node.branches.push({
					id: this.getRandomId(),
					parentId: node.id,
					type: this.isExclusiveNode(node) ? 'EXCLUSIVE' : 'PARALLEL',
					name: (this.isExclusiveNode(node) ? '条件' : '分支') + (node.branches.length + 1),
					props: this.isExclusiveNode(node) ? this.$utils.deepCopy(Constant.EXCLUSIVE_PROPS) : {},
					child: {}
				});
			} else {
				this.$message.warning('最多只能添加 8 项😥');
			}
		},
		//删除当前节点
		delNode(node) {
			//获取该节点的父节点
			let parentNode = this.nodeMap.get(node.parentId);
			if (parentNode) {
				//判断该节点的父节点是不是分支节点
				if (this.isBranchNode(parentNode)) {
					//移除该分支
					parentNode.branches.splice(parentNode.branches.indexOf(node), 1);
					//处理只剩1个分支的情况
					if (parentNode.branches.length < 2) {
						//获取条件组的父节点
						let ppNode = this.nodeMap.get(parentNode.parentId);
						//判断唯一分支是否存在业务节点
						if (parentNode.branches[0].child && parentNode.branches[0].child.id) {
							//将剩下的唯一分支头部合并到主干
							ppNode.child = parentNode.branches[0].child;
							ppNode.child.parentId = ppNode.id;
							//搜索唯一分支末端最后一个节点
							let endNode = this.getBranchEndNode(parentNode.branches[0]);
							//后续节点进行拼接, 这里要取EMPTY后的节点
							endNode.child = parentNode.child.child;
							if (endNode.child && endNode.child.id) {
								endNode.child.parentId = endNode.id;
							}
						} else {
							//直接合并分支后面的节点，这里要取EMPTY后的节点
							ppNode.child = parentNode.child.child;
							if (ppNode.child && ppNode.child.id) {
								ppNode.child.parentId = ppNode.id;
							}
						}
					}
				} else {
					//不是的话就直接删除
					if (node.child && node.child.id) {
						node.child.parentId = parentNode.id;
					}
					parentNode.child = node.child;
				}
				this.$forceUpdate();
			} else {
				this.$message.warning('出现错误，找不到上级节点😥');
			}
		},
		validateProcess() {
			this.valid = true;
			let err = [];
			this.validate(err, this.nodeInfo);
			return err;
		},
		validateNode(err, node) {
			if (this.$refs[node.id].validate) {
				this.valid = this.$refs[node.id].validate(err);
			}
		},
		//更新指定节点的dom
		nodeDomUpdate(node) {
			this.$refs[node.id].$forceUpdate();
		},
		//给定一个起始节点，遍历内部所有节点
		forEachNode(parent, node, callback) {
			if (this.isBranchNode(node)) {
				callback(parent, node);
				this.forEachNode(node, node.child, callback);
				node.branches.map((branchNode) => {
					callback(node, branchNode);
					this.forEachNode(branchNode, branchNode.child, callback);
				});
			} else if (this.isPrimaryNode(node) || this.isEmptyNode(node) || this.isBranchSubNode(node)) {
				callback(parent, node);
				this.forEachNode(node, node.child, callback);
			}
		},
		//校验所有节点设置
		validate(err, node) {
			if (this.isPrimaryNode(node)) {
				this.validateNode(err, node);
				this.validate(err, node.child);
			} else if (this.isBranchNode(node)) {
				//校验每个分支
				node.branches.map((branchNode) => {
					//校验条件节点
					this.validateNode(err, branchNode);
					//校验条件节点后面的节点
					this.validate(err, branchNode.child);
				});
				this.validate(err, node.child);
			} else if (this.isEmptyNode(node)) {
				this.validate(err, node.child);
			}
		}
	},
	watch: {}
};
</script>

<style lang="less" scoped>
._root {
	margin: 0 auto;
}
.process-end {
	width: 60px;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 15px;
	padding: 5px 10px;
	font-size: small;
	color: #747474;
	background-color: #f2f2f2;
	box-shadow: 0 0 10px 0 #bcbcbc;
}
.primary-node {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.branch-node {
	display: flex;
	justify-content: center;
	/*border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;*/
}
.branch-node-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: 2px solid #cccccc;
	border-bottom: 2px solid #cccccc;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: calc(50% - 1px);
		margin: auto;
		width: 2px;
		height: 100%;
		background-color: #cacaca;
	}
	.line-top-left,
	.line-top-right,
	.line-bot-left,
	.line-bot-right {
		position: absolute;
		width: 50%;
		height: 4px;
		background-color: #f5f6f6;
	}
	.line-top-left {
		top: -2px;
		left: -1px;
	}
	.line-top-right {
		top: -2px;
		right: -1px;
	}
	.line-bot-left {
		bottom: -2px;
		left: -1px;
	}
	.line-bot-right {
		bottom: -2px;
		right: -1px;
	}
}
.add-branch-btn {
	position: absolute;
	width: 80px;
	.add-branch-btn-el {
		z-index: 999;
		position: absolute;
		top: -15px;
	}
}

.empty-node {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
</style>
