<template>
	<div style="margin-top: 10px">
		<el-tag
			class="selected-item"
			v-for="(selected, index) in _value"
			:key="index + '_selected'"
			closable
			size="mini"
			@close="remove(index)"
		>
			{{ selected.nickname }}
		</el-tag>
	</div>
</template>

<script>
export default {
	name: 'SelectedItems',
	components: {},
	props: {
		value: {
			type: Array,
			default: () => {
				return []
			}
		}
	},
	computed: {
		_value: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		}
	},
	data() {
		return {}
	},
	methods: {
		remove(index) {
			this._value.splice(index, 1)
		}
	}
}
</script>

<style scoped>
.selected-item {
	margin: 5px;
}
</style>
