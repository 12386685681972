<template>
	<div>
		<p class="desc">选择能发起该审批的人员，不选则默认开放给所有人</p>
		<el-button size="mini" @click="showUserPicker" icon="el-icon-plus" type="primary" round>请选择</el-button>
		<selected-items v-model="select" />
		<user-picker title="请选择可发起本审批的人员" multiple ref="userPicker" :selected="select" @ok="selected" />
	</div>
</template>

<script>
import UserPicker from '../../components/UserPicker';
import SelectedItems from './SelectedItems';

export default {
	name: 'RootConfig',
	components: { UserPicker, SelectedItems },
	data() {
		return {};
	},
	computed: {
		select() {
			return this.$store.state.flowable.selectedNode.props.assignedUser;
		}
	},
	methods: {
		showUserPicker() {
			this.$refs.userPicker.show();
		},
		selected(select) {
			this.select.length = 0;
			select.forEach((val) => this.select.push(val));
		},
		removeOrgItem(index) {
			this.select.splice(index, 1);
		}
	}
};
</script>

<style lang="less" scoped>
.desc {
	font-size: small;
	color: #8c8c8c;
}
.org-item {
	margin: 5px;
}
</style>
